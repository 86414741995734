// inspired by:
// - https://gist.github.com/chmelevskij/10cdbba9642e5e0bfdb50914ea2b33d9
// - https://github.com/richardzcode/Journal-AWS-Amplify-Tutorial/tree/master/step-05
// -  https://docs.amplify.aws/lib/utilities/hub/q/platform/js/#listening-for-messages

import { Auth } from "aws-amplify"
import React, { useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { signIn, signOut, bootstrap } from "../auth/authReducer"
import { Hub } from "aws-amplify"
import { useAppDispatch } from "../../hooks"

export default function AmplifyBridge({ children }: { children: React.ReactNode }) {
  let location = useLocation()
  let navigate = useNavigate()
  const dispatch = useAppDispatch()

  Auth.currentAuthenticatedUser()
    .then((data) => dispatch(bootstrap({ username: data.username, loggedIn: true })))
    .catch((err) => dispatch(bootstrap({ username: "", loggedIn: false })))

  useEffect(() => {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          // TODO: break out into navigation file of route constants
          const DEFAULT_LOGGED_IN_DESTINATION = "/dashboard"
          console.log("signIn")
          dispatch(signIn(data.payload.data.username))
          let from = location.state?.from?.pathname || DEFAULT_LOGGED_IN_DESTINATION
          navigate(from, { replace: true })
          break
        case "signOut":
          console.log("signOut")
          dispatch(signOut())
          break
      }
    })
  })
  return <>{children}</>
}
