import { Amplify } from "aws-amplify"
import "@aws-amplify/ui-react/styles.css"
import { amplifyConfig } from "./config/amplify-config"
import Marco from "./components/marco/Marco"
import RequireAuth from "./components/require-auth/RequireAuth"
import { Route, Routes } from "react-router-dom"

import Root from "./layout/Root"
import Home from "./pages/home/Home"
import Login from "./pages/login/Login"
import Dashboard from "./pages/dashboard/Dashboard"
import NotFound from "./pages/not-found/NotFound"

import { useAppSelector } from "./hooks"
import LogOut from "./pages/log-out/LogOut"

import { Link } from "react-router-dom"

Amplify.configure(amplifyConfig)

//function App({ signOut, user }) {
function App() {
  const { initialStateLoaded } = useAppSelector((state) => state.auth)

  if (!initialStateLoaded) {
    return <>Initialising...</>
  } else {
    return (
      <>
        <Marco></Marco>
        <div>
          <Link to="/">Home</Link>
        </div>
        <div>
          <Link to="/login">Login</Link>
        </div>
        <div>
          <Link to="/dashboard">Dasboard</Link>
        </div>
        <div>
          <Link to="/logout">Log Out</Link>
        </div>
        <Routes>
          <Route path="/" element={<Root />}>
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<LogOut />} />
            <Route
              path="dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            {/* Using path="*"" means "match anything", so this route
              acts like a catch-all for URLs that we don't have explicit
              routes for. */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </>
    )
  }
}

export default App
//export default withAuthenticator(App, { signUpConfig, usernameAttributes });

// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
