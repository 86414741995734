import axios from "axios"

export async function getMarco() {
  try {
    //const response = await axios.get("https://h72pbpmke7.execute-api.ap-southeast-2.amazonaws.com/v1/marco", {
    const response = await axios.get(`${process.env.REACT_APP_BFF_BASE_URL}/v1/marco`, {
      headers: {}
      //withCredentials: true
    })
    return response.data
  } catch (error) {
    //console.error(error)
    throw error
  }
}

export async function getMarcoSecure(accessToken: string) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BFF_BASE_URL}/v1/marco-secure`, {
      //const response = await axios.get("http://localhost:3010/local/v1/marco-secure", {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
      //withCredentials: true
    })
    return response.data
  } catch (error) {
    //console.error(error)
    throw error
  }
}

// `withCredentials` indicates whether or not cross-site Access-Control requests
// should be made using credentials
//withCredentials: false, // default
