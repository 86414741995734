import { createAction, createReducer } from "@reduxjs/toolkit"
import { Hub } from "aws-amplify"

interface AuthState {
  username: string
  loggedIn: boolean
  initialStateLoaded: boolean
}

interface Bootstrap {
  username: string
  loggedIn: boolean
}

export const bootstrap = createAction<Bootstrap>("auth/setInitialState")
export const signIn = createAction<string>("auth/signIn")
export const signOut = createAction("auth/signOut")

const initialState = { username: "", loggedIn: false, initialStateLoaded: false } as AuthState

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(bootstrap, (state, action) => {
      state.username = action.payload.username
      state.loggedIn = action.payload.loggedIn
      state.initialStateLoaded = true
    })
    .addCase(signIn, (state, action) => {
      state.username = action.payload
      state.loggedIn = true
    })
    .addCase(signOut, (state, action) => {
      state.username = ""
      state.loggedIn = false
    })
})

export default authReducer
