import { Auth } from "aws-amplify"
import { useEffect } from "react"
import { useAppSelector } from "../../hooks"

function LogOut() {
  const { loggedIn } = useAppSelector((state) => state.auth)

  useEffect(() => {
    Auth.signOut()
  })

  if (loggedIn) {
    return <>Logging you out...</>
  }
  return <>You have been logged out.</>
}

export default LogOut
