import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getMarcoSecure } from "../../api/api"

export const fetchMarcoSecure = createAsyncThunk(
  "marco/fetchMarcoSecure",
  async (accessToken: string) => {
    const response = await getMarcoSecure(accessToken)
    return response
  }
)

interface MarcoSecureState {
  val: string
  status: string
  //TODO: replace any
  error: any
}

const initialState: MarcoSecureState = {
  val: "",
  status: "idle",
  error: null
}

export const marcoSlice = createSlice({
  name: "marco",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMarcoSecure.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(fetchMarcoSecure.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.val = action.payload.response
      })
      .addCase(fetchMarcoSecure.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error
      })
  }
})

//export const { increment, decrement, incrementByAmount } = marcoSlice.actions

export default marcoSlice.reducer
