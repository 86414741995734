import { Auth } from "aws-amplify"
import MarcoSecure from "../../components/marco-secure/MarcoSecure"

function Dashboard() {
  return (
    <>
      <div>Dashboard!</div>
      <MarcoSecure></MarcoSecure>
    </>
  )
}

export default Dashboard
