import { fetchMarco } from "./marcoSlice"
import React, { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks"

function Marco() {
  const dispatch = useAppDispatch()

  const { val, status, error } = useAppSelector((state) => state.marco)
  //const marcoValue = useAppSelector((state) => state.marco.val)
  //const marcoStatus = useAppSelector((state) => state.marco.status)
  //const error = useAppSelector((state) => state.marco.error)

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchMarco())
    }
  }, [status, dispatch])

  let content

  if (status === "loading") {
    content = <div>Loading...</div>
  } else if (status === "succeeded") {
    content = <div>{val}</div>
  } else if (status === "failed") {
    content = <div>{error}</div>
  }

  return (
    <section className="marco-data">
      <div>Marco... {content}</div>
    </section>
  )
}

export default Marco
