import { Authenticator } from "@aws-amplify/ui-react"
import {} from "@aws-amplify/auth"

import { useLocation, Navigate } from "react-router-dom"

const formFields = {
  signUp: {
    name: {
      order: 1
    }
  }
}

function Login() {
  let location = useLocation()

  return (
    <>
      <div>Login!</div>

      <Authenticator
        signUpAttributes={["email", "name"]}
        loginMechanisms={["email"]}
        socialProviders={["google"]}
        formFields={formFields}
      >
        {({ signOut, user }) => {
          const DEFAULT_LOGGED_IN_DESTINATION = "/dashboard"
          let from = location.state?.from?.pathname || DEFAULT_LOGGED_IN_DESTINATION
          return <Navigate to={from} state={{ from: location }} replace />
          // return (
          //   <main>
          //     <h1>Hello {user && user.username}</h1>
          //     <button onClick={signOut}>Sign out</button>
          //   </main>
          // )
        }}
      </Authenticator>
    </>
  )
}

export default Login
