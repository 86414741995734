import { fetchMarcoSecure } from "./marcoSecureSlice"
import React, { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { Auth } from "aws-amplify"

function MarcoSecure() {
  const dispatch = useAppDispatch()

  const { val, status, error } = useAppSelector((state) => state.marcoSecure)
  //const marcoValue = useAppSelector((state) => state.marco.val)
  //const marcoStatus = useAppSelector((state) => state.marco.status)
  //const error = useAppSelector((state) => state.marco.error)

  useEffect(() => {
    Auth.currentSession()
      .then((data) => {
        const accessToken = data.getAccessToken().getJwtToken()
        // TODO: don't log to console
        //console.log(accessToken)
        if (status === "idle") {
          dispatch(fetchMarcoSecure(accessToken))
        }
      })
      .catch((err) => console.log(err))

    // if (status === "idle") {
    //   dispatch(fetchMarcoSecure())
    // }
  }, [status, dispatch])

  let content

  if (status === "loading") {
    content = <div>Loading...</div>
  } else if (status === "succeeded") {
    content = <div>{val}</div>
  } else if (status === "failed") {
    content = <div>{error}</div>
  }

  return (
    <section className="marco-data">
      <div>Marco secure... {content}</div>
    </section>
  )
}

export default MarcoSecure
