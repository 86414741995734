import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getMarco } from "../../api/api"

export const fetchMarco = createAsyncThunk("marco/fetchMarco", async () => {
  const response = await getMarco()
  return response
})

interface MarcoState {
  val: string
  status: string
  //TODO: replace any
  error: any
}

const initialState: MarcoState = {
  val: "",
  status: "idle",
  error: null
}

export const marcoSlice = createSlice({
  name: "marco",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMarco.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(fetchMarco.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.val = action.payload.response
      })
      .addCase(fetchMarco.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error
      })
  }
})

//export const { increment, decrement, incrementByAmount } = marcoSlice.actions

export default marcoSlice.reducer
