import { configureStore } from "@reduxjs/toolkit"
import authReducer from "./components/auth/authReducer"
import marcoReducer from "./components/marco/marcoSlice"
import marcoSecureReducer from "./components/marco-secure/marcoSecureSlice"

export const store = configureStore({
  reducer: {
    auth: authReducer,
    marco: marcoReducer,
    marcoSecure: marcoSecureReducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
