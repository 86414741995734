import { Navigate, useLocation } from "react-router-dom"
import { useAppSelector } from "../../hooks"

export default function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation()
  //let navigate = useNavigate()

  const { loggedIn } = useAppSelector((state) => state.auth)

  if (!loggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />
    // navigate({
    //   pathname: "/login",
    //   search: "?sort=date&order=newest"
    // })
  }

  return children
}
